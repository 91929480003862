<template>
  <div>
    <div class="container">
      <div class="notice-box">
        <!-- 头部条 -->
        <div class="header">
          <!-- 返回按钮 -->
          <div class="back">
            <el-button type="text" icon="el-icon-d-arrow-left" @click="goBack">返回</el-button>
          </div>
          <!-- 缩略图与列表切换按钮 -->
          <div class="change-show-status" @click="changeShowStatus">
            <div class="status-icon"><svg-icon name="list" width="18" /></div>
            <span v-if="isList" class="status-text">切换为列表</span>
            <span v-else class="status-text">切换为缩略图</span>
          </div>
          <!-- 评审名称 -->
          <div class="hd-name">
            {{ judgeName }}
          </div>
          <!-- 筛选框 -->
          <div class="hd-select">
            <el-select v-model="status_selected" class="select-box" size="small" placeholder="请选择" @change="getWorkList">
              <el-option
                v-for="item in statuslist"
                :key="item"
                :value="item"
                :lable="item"
              />
            </el-select>
            <el-select
              v-model="score_selected"
              class="select-box"
              size="small"
              placeholder="请选择"
              :disabled="checkScoreRangeSelectDisabled()"
              @change="getWorkList"
            >
              <el-option
                v-for="item in scorelist"
                :key="item"
                :value="item"
                :lable="item"
              />
            </el-select>
          </div>
          <!-- 数量统计显示 -->
          <div class="hd-statistic">
            <div v-for="propName in statusShowlist" :key="propName" class="hd-stta-box">
              <span>{{ propName }}: </span>
              <span :class="[propName !== '未评审' ? 'theme-color' : 'red']">{{ statisticRecord[propName] }}</span>
            </div>
            <div class="hd-stta-box">
              <span>作品总数: </span>
              <span>{{ workTotal }}</span>
            </div>
          </div>
        </div>

        <!-- 列表展示 -->
        <div v-if="isList" class="table-box">
          <el-table
            ref="multipleTable"
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            :cell-style="cellStyle"
          >
            <el-table-column width="120">
              <template slot="header">
                <div class="table-header">序号</div>
              </template>
              <template slot-scope="scope">{{ scope.row.boardId }}</template>
            </el-table-column>
            <el-table-column width="400" show-overflow-tooltip>
              <template slot="header">
                <div class="table-header">作品名称</div>
              </template>
              <template slot-scope="scope">{{ scope.row.workName }}</template>
            </el-table-column>
            <!-- <el-table-column prop="groupNumber" label="组员数" width="150" /> -->
            <el-table-column width="240">
              <template slot="header">
                <div class="table-header">作品分类</div>
              </template>
              <template slot-scope="scope">{{ scope.row.racetrackName + ' / ' + scope.row.catagoryName }}</template>
            </el-table-column>
            <el-table-column width="100">
              <template slot="header">
                <div class="table-header">我的评分</div>
              </template>
              <template slot-scope="scope">{{ scope.row.score }}</template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table-header">操作</div>
              </template>
              <template slot-scope="scope">
                <div class="table-check" @click="onShowWorkDetail(scope.$index)">
                  评审<i class="el-icon-edit-outline" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 缩略图展示 -->
        <div v-else class="thumbnail-box">
          <el-row v-for="(row, r_id) in 2" :key="row" :gutter="10">
            <el-col v-for="(col, col_id) in 6" :key="col" :span="4">
              <div v-if="(r_id*6 + col_id) < cardNumber">
                <div class="info-img">
                  <el-image
                    class="img"
                    :src="list[r_id*6 + col_id].posterGenSrc"
                    lazy
                  />
                  <div class="img-add-btn" @click="onShowWorkDetail(r_id*6 + col_id)">评审</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 分页栏 -->
      <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" :background="true" @pagination="getWorkList" />
    </div>
  </div>
</template>

<script>
import '@/icons/list'
import Pagination from '@/components/Pagination'
import { getExpertJudgeStatistics, getOwnJudge } from '@/api/expert'
import { getJudgeInfo } from '@/api/judge' // secondary package based on el-pagination

export default {
  components: { Pagination },
  data() {
    return {
      curJudgeId: 0,
      curJudgeInfo: {},
      statuslist: ['全部', '未评审', '待定', '已评审'],
      statusShowlist: ['已评审', '待定', '未评审'],
      status_selected: '全部',
      scorelist: ['——', '<60分', '60-70分', '70-80分', '80-90分', '90-100分'],
      scoreRangeMap: new Map([
        ['——', [0, 101]],
        ['<60分', [0, 60]],
        ['60-70分', [60, 70]],
        ['70-80分', [70, 80]],
        ['80-90分', [80, 90]],
        ['90-100分', [90, 101]]
      ]),
      score_selected: '——',

      statisticRecord: {
        '未评审': 0,
        '正在评审': 0,
        '已评审': 0,
        '待定': 0,
        total: 0
      },
      isList: true, // 判断是列表还是缩略图显示

      // 作品列表
      list: [{
        'modelSrc': '',
        'expertId': 0,
        'posterGenSrc': '',
        'specification': '',
        'videoSrc': '',
        'workName': '',
        'workId': 0,
        'score': 0,
        'contestId': 0,
        'racetrackName': '0',
        'judgeStatus': 0,
        'catagoryName': '0',
        'boardId': '0',
        'id': 0,
        'judgeId': 0
      }],

      // 分页内容
      total: 10,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      }
    }
  },
  computed: {
    judgeName() {
      return this.curJudgeInfo.judgeName
    },
    workTotal() {
      return this.statisticRecord.total
    },
    cardNumber() {
      return this.list.length
    },
    condition() {
      const status = this.status_selected === '全部' ? null : this.status_selected
      const scoreRange = this.scoreRangeMap.get(this.score_selected)
      return {
        pageNum: this.listQuery.page,
        pageSize: this.listQuery.limit,
        judgeId: this.curJudgeId,
        judgeStatus: status,
        scoreRangeMinInclusive: scoreRange[0],
        scoreRangeMaxExclusive: scoreRange[1],
        curIndex: 0,
        curJudgeId: this.curJudgeId,
        curJudgeStatus: this.curJudgeInfo.status
      }
    }
  },
  mounted() {
    this.curJudgeId = parseInt(this.$route.params.judgeId)
    this.getCurrentJudgeDetail()
    this.getWorkList()
    this.getExpertJudgeStatistics()
  },
  methods: {
    getExpertJudgeStatistics() {
      getExpertJudgeStatistics(this.curJudgeId).then(res => {
        this.statisticRecord = res.data
      })
    },
    getCurrentJudgeDetail() {
      getJudgeInfo(this.curJudgeId).then(res => {
        this.curJudgeInfo = res.data
      })
    },
    // 切换显示状态
    changeShowStatus() {
      this.isList = !this.isList
    },
    // 根据所选状态与所选分数显示对应的作品列表
    getWorkList() {
      const condition = this.condition
      getOwnJudge(condition.pageNum, condition.pageSize, condition.judgeId,
        condition.judgeStatus, condition.scoreRangeMinInclusive, condition.scoreRangeMaxExclusive).then(res => {
        this.list = res.data.items
        this.list.forEach(item => {
          item.posterGenSrc = this.PR.previewTiny(item.posterGenSrc)
        })
        this.total = res.data.total
      })
    },
    checkScoreRangeSelectDisabled() {
      if (this.status_selected !== '已评审') {
        console.log(this.status_selected)
        this.score_selected = '——'
        return true
      }
      return false
    },
    // 根据id查看作品
    onShowWorkDetail(idx) {
      this.condition.curIndex = idx
      this.$store.commit('judge/SET_JUDGE_WORK_INFO', this.condition)
      this.$router.push('/teacher/judgework')
    },
    // 返回到评审列表界面
    goBack() {
      this.$router.push('/teacher/judgelist')
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 0) {
        return 'padding-left: 8px'
      }
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1050px;
  /* border: 1px solid red; */
}

.header {
  height: 60px;
  background-color: #c8c8c9;
  border-radius: 10px 10px 0 0;
  text-align: left;
  font-size: 14px;
  /* line-height: 60px; */
}

.back {
  display: inline-block;
  line-height: 60px;
  margin-left: 20px;
}

.change-show-status {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  line-height: 60px;
  margin-left: 10px;
  font-weight: 800;
}

.change-show-status:hover {
  cursor: pointer;
}

.status-icon {
  position: absolute;
  display: inline-block;
  top: 4px;
  /* padding-top: 10px; */
}

.status-text {
  display: inline-block;
  margin-left: 22px;
  width: 85px;
}

/* 若超出，以...显示 */
.hd-name {
  display: inline-block;
  margin-left: 5px;
  width: 290px;
  padding: 2px 5px;
  border-left: 2px solid rgb(155, 155, 155);
  border-right: 2px solid rgb(155, 155, 155);
  /*1. 先强制一行内显示文本*/
  white-space: nowrap; /*默认 normal 自动换行*/
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号代替超出的部分*/
  text-overflow: ellipsis;
  margin-bottom: 19px;
  vertical-align: bottom;
}

.hd-select {
  display: inline-block;
}

.el-select {
  width: 90px;
  height: 30px;
  margin-left: 5px;
  /* font-size: 14px; */
}

.select-box /deep/ .el-input {
  font-size: 10px;
}

.hd-statistic {
  position: absolute;
  line-height: 60px;
  display: inline-block;
  right: 20px;
}

.hd-stta-box {
  display: inline-block;
  margin-left: 5px;
  font-weight: 800;
}

.red {
  color: red;
}

.theme-color {
  color: var(--theme-color);
}

/* 列表 */
.table-box {
  width: 95%;
  margin: auto;
  margin-top: 5px;
}

.table-header {
  /* background-color: red; */
  border-left: 2px solid rgb(184, 182, 182);
  padding-left: 5px;
  color: rgb(77, 74, 74);
}

.table-check {
  width: 50%;
  color: var(--theme-color);
}

.table-check:hover {
  cursor: pointer;
}

/* 缩略图 */
.thumbnail-box {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.info-img {
  position: relative;
  margin-bottom: 15px;
}

.img {
  width: 100%;
}

.img-add-btn {
  display: none;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25px;
  /* border-radius: 2px; */
  background-color: rgba(0,0,0,.8);
  text-align: center;
  font-size: 13px;
  line-height: 25px;
  color: cornsilk;
}

.info-img:hover .img-add-btn {
  display: block;
}

.img-add-btn:hover {
  cursor: pointer;
}

</style>
